import React, { useState, useEffect } from "react";
import "./filtrationPanelJudge.css";
import Table from "../../../common/projectsTable/index";
import * as _ from "lodash";
import InternalEventNavbar from "../../../common/internalEventNavbar/navBar/navBar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import FiltrationPanelProjectDetails from "./filtrationPanelProjectDetails";
import io from "socket.io-client";
import SideMenu from "../../../common/sideMenu/sideMenu";
import LoggedNavBar from "../../../common/navBar/loggedNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralLoader from "../../../common/submissionLoader/GeneralLoader";
function FiltrationPanelJudge() {
  const location = useLocation();
  const history = useNavigate();
  const [values, setValues] = useState([]);
  const [criterias, setCriterias] = useState([]);
  const [loading, setloading] = useState(true);
  const [evaluations, setEvaluations] = useState([]);
  const [ProjectID, setProjectID] = useState("");
  const [projectDetails, setprojectDetails] = useState({});
  const [panelStatus, setpanelStatus] = useState(false);
  const [showJustificationModal, setShowJustificationModal] =
    React.useState(false);
  const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;
  const screenref = React.useRef();
  let socket;
  let socketID;
  useEffect(() => {
    socket = io(ENDPOINT, { path: "/socket.io" });

    socket.on("connect", function () {
      socketID = socket.id;
    });
    socket.emit(
      "joinDefaultRoom",
      { panelID: location.state.panelID },
      (error) => {
        if (error) {
          alert(error);
        }
      }
    );
    socket = io(ENDPOINT, { path: "/socket.io" });
    socket.on("calculated", (message) => {
      history("/judgesCalculated", {
        state: { panelID: location.state.panelID },
      });
    });
  }, [ENDPOINT, socketID]);
  useEffect(() => {
    getPanelValues();
    getPanelStatus();
    // Scroll to top on initial load
    window.scrollTo(0, 0);
    window.$(document).ready(function () {
      window.$("#comments").on("hide.bs.modal", function (e) {
        window.$("#loader-screen").css("display", "flex");
      });
      window.$("#comments").on("hidden.bs.modal", function (e) {
        e.preventDefault();

        window.$("body").css("padding-right", "0"); // Reset the padding added to the body
        window.$("body").removeClass("modal-open"); // Remove the 'modal-open' class from the body
        setTimeout(() => {
          window.$("html, body").scrollTop(window.$(this).data("scroll-top"));
        }, 400);
        setTimeout(() => {
          window.$("#loader-screen").css("display", "none");
        }, 1000); // Set the scroll position to the original position
      });

      // Save the scroll position when the modal is shown
      window.$("#comments").on("show.bs.modal", function () {
        window.$(this).data("scroll-top", window.$(window).scrollTop());
      });

      window.$("#projectDetails").on("hide.bs.modal", function (e) {
        window.$("#loader-screen").css("display", "flex");
      });
      window.$("#projectDetails").on("hidden.bs.modal", function (e) {
        e.preventDefault();

        window.$("body").css("padding-right", "0"); // Reset the padding added to the body
        window.$("body").removeClass("modal-open"); // Remove the 'modal-open' class from the body
        setTimeout(() => {
          window.$("html, body").scrollTop(window.$(this).data("scroll-top"));
        }, 400);
        setTimeout(() => {
          window.$("#loader-screen").css("display", "none");
        }, 1000); // Set the scroll position to the original position
      });

      // Save the scroll position when the modal is shown
      window.$("#projectDetails").on("show.bs.modal", function () {
        window.$(this).data("scroll-top", window.$(window).scrollTop());
      });
    });
  }, [location.state.groupID]);
  useEffect(() => {
    if (panelStatus) {
      history("/judgesCalculated", {
        state: { panelID: location.state.panelID },
      });
    }
  }, [panelStatus]);

  const getPanelStatus = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/panelStatus`, {
        panelID: location.state.panelID,
      })
      .then((res) => {
        setpanelStatus(res.data);
      });
  };
  const getPanelValues = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}panelvalues/getpanelValues`, {
        panelID: location.state.panelID,
        email: location.state.email,
        groupID: location.state.groupID,
      })
      .then((res) => {
        setCriterias(
          res.data.criterias.map((r) => {
            return {
              criteriaId: r._id,
              name: r.name,
              weight: r.maxWeight,
            };
          })
        );
        setEvaluations(
          res.data.vals.map((e) => {
            console.log(e);
            return {
              projectId: e.project,
              criteriaId: e.criteria,
              projectName: e.projectName,
              criteriaName: e.criteriaName,
              value: e.value,
              email: e.email,
              fromMixedSL: e.fromMixedSL,
            };
          })
        );
        setloading(false);
      });
  };
  const setProjectsValues = (value) => {
    let updatedEvaluations = evaluations.map((item) => {
      if (
        item.projectId === value.projectId &&
        item.criteriaId === value.criteriaId
      ) {
        console.log("eval", evaluations);
        if (
          evaluations.filter(
            (r) => r.projectId === value.projectId && r.value != 0
          ).length == criterias.length
        ) {
          setProjectID(value.projectId);
          document.getElementById("commentstriger").click();
        }

        return { ...item, value: value.value };
      } else {
        return item;
      }
    });

    setEvaluations(updatedEvaluations);
  };
  const openModal = (projectID) => {
    setProjectID(projectID);
    document.getElementById("detailsModalTriger").click();
  };

  useEffect(() => {
    let projectsArrs = _.groupBy(evaluations, "projectId");
    console.log(projectsArrs);
    let result = [];
    for (const key in projectsArrs) {
      var evaluationsIds = projectsArrs[key].map((projectArr) => {
        return projectArr.criteriaId;
      });

      var criteriasIds = criterias.map((criteria) => {
        return criteria.criteriaId;
      });

      var miss = criteriasIds.filter((singleCriteriaId) => {
        return evaluationsIds.indexOf(singleCriteriaId) == -1;
      });

      miss.map((singleMiss) => {
        result.push({
          projectId: projectsArrs[key][0].projectId,
          criteriaId: singleMiss,
          projectName: projectsArrs[key][0].projectName,
          criteriaName: criterias.find((item) => item.criteriaId === singleMiss)
            .name,
          value: 0,
          email: "",
          hasAddition: projectsArrs[key][0].isTwoPercent,
        });
      });
    }
    let sortedResult = _.sortBy(
      [...evaluations, ...result],
      ["projectId", "criteriaId"]
    );
    setEvaluations(sortedResult);
  }, [values]);
  const [showmodal, setshowmodal] = useState(false);

  const [commentsCount, setcommentsCount] = useState(0);
  const [addMoreComment, setAddMoreComment] = useState(false);
  const [teamMemberID, setteamMemberID] = useState("");
  const [comments, setComments] = useState("");
  const [teamMember, setteamMember] = useState({});

  const addComment = () => {
    setAddMoreComment(true);
  };
  const saveComments = () => {
    evaluations
      .filter((r) => r.projectId === ProjectID && r.value != 0)
      .map((l) => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}panelvalues/editpanelvalue`,
            {
              projectID: l.projectId,
              criteriaID: l.criteriaId,
              value: l.value,
              email: location.state.email,
              groupID: location.state.groupID,
              edit: true,
            }
          )
          .then((res) => {});
      });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}filterationpanelcomment/panelcomments`,
        {
          email: location.state.email,
          panelId: location.state.panelID,
          projectId: ProjectID,
          groupId: location.state.groupID,

          comments: [comments],
        }
      )
      .then((res) => {
        document.getElementById("commentstriger").click();
        setProjectID("");
        setComments("");
      });
  };

  const addComments = () => {
    return (
      <div className='details-main-container'>
        <h6>Score justification is Required</h6>
        <div className='row'>
          <div className='col-md-12'>
            <textarea
              className='form-control'
              placeholder='Please add a constructive comment'
              id='comment'
              name='comment'
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </div>

        <div className='row mt-3 mb-3'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <button
              className='btn btn-outline-light w-100'
              onClick={saveComments}
              disabled={comments == "" ? true : false}
            >
              Save
            </button>
          </div>
          <div className='col-md-4'></div>
        </div>
      </div>
    );
  };
  return (
    <>
      <button
        className=' add-group-btn  m-2'
        data-toggle='modal'
        data-target='#comments'
        id='commentstriger'
        style={{ position: "absolute", zIndex: "-2" }}
      ></button>
      <div
        className='modal fade'
        id='comments'
        tabindex='-1'
        role='dialog'
        aria-labelledby='comments'
        aria-hidden='true'
        data-backdrop='static'
      >
        <div className='modal-dialog modal-lg ' role='document'>
          <div className='modal-content add-groups-modal-body'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                FeedBack Form
              </h5>
            </div>
            <div className='row mt-3 ml-2 mr-2'>
              <div className='details-main-container col-md-12'>
                {addComments()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='panel-judge-container'
        style={{ height: "100%", width: "100%" }}
        ref={screenref}
      >
        <LoggedNavBar to='hall' />

        <div
          className='coaches-pageContainer'
          style={{ backgroundColor: "#191818" }}
        >
          <div className='container'>
            <div
              className='row asc-cards-flip-container '
              style={{ margin: "0", padding: "0" }}
            >
              <div className='col-md-12 col-sm-12'>
                {loading ? (
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : criterias.length != 0 && evaluations.length != 0 ? (
                  <Table
                    evaluations={evaluations}
                    criterias={criterias}
                    setValues={setProjectsValues}
                    openModal={openModal}
                    displayType='edit'
                  ></Table>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeneralLoader></GeneralLoader>
      <button
        className=' add-group-btn  m-2'
        data-toggle='modal'
        data-target='#projectDetails'
        id='detailsModalTriger'
        style={{ position: "absolute", zIndex: "-2" }}
        onClick={() => setshowmodal(!showmodal)}
      ></button>
      <div
        className='modal fade bd-example-modal-lg'
        id='projectDetails'
        tabindex='-1'
        role='dialog'
        aria-labelledby='projectDetails'
        data-backdrop='static'
        aria-hidden='true'
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <FiltrationPanelProjectDetails
          project={projectDetails}
          projectID={ProjectID}
          panelID={location.state.panelID}
          groupID={location.state.groupID}
          email={location.state.email}
          showPopup={showmodal}
          onclose={() => {
            setshowmodal(false);
          }}
        ></FiltrationPanelProjectDetails>
      </div>
    </>
  );
}

export default FiltrationPanelJudge;
