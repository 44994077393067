import axios from "axios";
import React, { useEffect, useState } from "react";
import reactSelectCjs from "react-select";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./filtrationPanelAddGroup.css";

const convertToGMT = (localDate) => {
  if (!localDate) return null;
  const date = new Date(localDate);
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

const convertToLocal = (gmtDate) => {
  if (!gmtDate) return new Date();
  return new Date(gmtDate);
};

function FiltrationPanelAddGroup({ panel, type, isEdit, group }) {
  const changeDate = (inputDate) => {
    if (!inputDate) return "";
    const date = new Date(inputDate);
    // Define the options for formatting the date
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    // Get the formatted date string
    return date.toLocaleString("en-US", options);
  };

  const [link, setroomlink] = useState("");
  const [name, setname] = useState("");
  const [panelID, setpanelID] = useState(panel);

  const [members, setmembers] = useState([]);
  const [membersDDL, setmembersDDL] = useState([]);
  const [projects, setprojects] = useState([]);
  const [projectsDDL, setprojectsDDL] = useState([]);

  useEffect(() => {
    getMembers();
    getProjects();
    setroomlink("");
    setname("");
    setmembers([]);
    setprojects([]);
    if (isEdit) getGroup();
  }, [type, group]);

  const getProjects = () => {
    console.log("Fetching projects for panel:", panelID);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/projectsddl`, {
        panelID: panelID,
      })
      .then((res) => {
        console.log("Projects response:", res.data);
        setprojectsDDL(
          res.data.map((response) => ({
            value: response._id,
            label: response.name,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        if (error.response) {
          console.error("Error response:", error.response.data);
        }
      });
  };
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const getMembers = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/membersddl`, {
        panelID: panelID,
      })
      .then((res) => {
        setmembersDDL(
          res.data.map((response) => ({
            value: response._id,
            label: response.email,
          }))
        );
      });
  };

  const createGroup = () => {
    // console.log(projects);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/addgroup`, {
        panelID: panelID,
        name,
        link,
        members,
        projects,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  const getGroup = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/getforedit`, {
        groupID: group,
      })
      .then((res) => {
        console.log(res.data.groupProjects);
        setprojects(
          res.data.groupProjects.map((response) => ({
            name: response.project.name,
            displayOrder: response.displayOrder,
            // Convert GMT times from server to local time for display
            from: convertToLocal(response.from),
            to: convertToLocal(response.to),
            _id: response._id,
          }))
        );
        setroomlink(res.data.group.link);
      });
  };
  const editDisplay = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}adminfiltration/editDisplay`, {
        groupID: group,
        link,
        projects,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  return (
    <div className='modal-dialog modal-md' role='document'>
      <div className='modal-content add-groups-modal-body'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {isEdit ? "Edit Display oreder" : "Add Group"}
          </h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body row'>
          {type == "evaluation-panel" && isEdit != true ? (
            <div className='col-md-12 mb-2'>
              <span style={{ color: "#e60000" }}>
                (i) "Time as local timezone"
              </span>
            </div>
          ) : null}
          {isEdit != true ? (
            <div className='col-md-12 mb-2'>
              <label htmlFor='name'>Name</label>
              {/* <p> Name:</p> */}
              <input
                type='text'
                className='add-group-text-box'
                style={{ width: "100%" }}
                onChange={(el) => {
                  setname(el.target.value);
                }}
                value={name}
              ></input>
            </div>
          ) : null}
          {type == "evaluation-panel" ? (
            <div className='col-md-12 mb-2'>
              {/* <p>Link:</p> */}
              <label htmlFor='link'>Link</label>
              <input
                type='text'
                className='add-group-text-box'
                style={{ width: "100%" }}
                defaultValue={link}
                onChange={(el) => {
                  setroomlink(el.target.value);
                }}
                value={link}
              ></input>
            </div>
          ) : null}
          {isEdit != true ? (
            <div className='col-md-12 mb-2'>
              <label htmlFor='members'>Members</label>
              {/* <p>Members:</p> */}
              <Select
                isMulti
                isSearchable
                options={membersDDL}
                className='options'
                onChange={(e) => {
                  setmembers(e.map((el) => el.label));
                }}
              />
            </div>
          ) : null}{" "}
          {type == "evaluation-panel" ? (
            <>
              {projects.map((e, i) => (
                <>
                  <div className='col-md-12 mb-2'>
                    <label htmlFor='projects'>Project</label>
                    {/* <p>Projects:</p> */}
                    {isEdit ? (
                      <h2 style={{ color: "white" }}>{projects[i].name}</h2>
                    ) : (
                      <Select
                        isSearchable
                        options={projectsDDL}
                        className='options'
                        onChange={(x) => {
                          let proj = projects;
                          proj[i].name = x.label;
                          proj[i].value = x.value;
                          setprojects(proj);
                          forceUpdate();
                        }}
                      />
                    )}
                  </div>
                  <div className='col-md-12 mb-2'>
                    <label htmlFor='projects'>Display Order</label>
                    {/* <p>Projects:</p> */}
                    <div>
                      <input
                        type='text'
                        className='options'
                        style={{ width: "100%" }}
                        defaultValue={
                          projects[i].displayOrder
                            ? projects[i].displayOrder
                            : 0
                        }
                        onChange={(x) => {
                          let proj = projects;

                          proj[i].displayOrder = x.target.value;

                          setprojects(proj);
                          forceUpdate();
                        }}
                      />
                    </div>
                  </div>

                  <>
                    {" "}
                    <div
                      className='col-md-12 mb-2'
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label htmlFor='projects'>FROM</label>
                      {/* <p>Projects:</p> */}
                      <DateTimePicker
                        className='picker'
                        value={
                          projects[i].from
                            ? convertToLocal(projects[i].from)
                            : new Date()
                        }
                        onChange={(e) => {
                          // let proj = projects;
                          // proj[i].from = e;
                          // setprojects(proj);
                          // forceUpdate();
                          let proj = [...projects]; // Create a new array to avoid direct state mutation
                          proj[i] = {
                            ...proj[i],
                            from: e,
                          };
                          setprojects(proj);
                        }}
                      />
                    </div>
                    <div
                      className='col-md-12 mb-2'
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label htmlFor='projects'>TO</label>
                      {/* <p>Projects:</p> */}
                      <DateTimePicker
                        className='picker'
                        value={
                          projects[i].to
                            ? convertToLocal(projects[i].to)
                            : new Date()
                        }
                        onChange={(e) => {
                          // let proj = projects;
                          // proj[i].to = e;
                          // setprojects(proj);
                          // forceUpdate();
                          let proj = [...projects]; // Create a new array to avoid direct state mutation
                          proj[i] = {
                            ...proj[i],
                            to: e,
                          };
                          setprojects(proj);
                        }}
                      />
                    </div>
                  </>
                </>
              ))}
              {isEdit != true ? (
                <div className='adding-project col-md-12'>
                  <label htmlFor='projects'>Schedule</label>
                  <label
                    htmlFor='add-icon'
                    onClick={() => {
                      let proj = projects;
                      proj.push({
                        name: "",
                        from: new Date(),
                        to: new Date(),
                        displayOrder: 0,
                      });
                      forceUpdate();
                    }}
                  >
                    +
                  </label>
                </div>
              ) : null}
            </>
          ) : (
            <div className='col-md-12 mb-2'>
              <label htmlFor='projects'>Projects</label>
              {/* <p>Projects:</p> */}
              <Select
                isMulti
                isSearchable
                options={projectsDDL}
                className='options'
                onChange={(e) => {
                  setprojects(e.map((el) => el.label));
                }}
              />
            </div>
          )}
        </div>
        <div className='modal-footer' style={{ justifyContent: "center" }}>
          <button
            type='button'
            className='add-groups-modal-save-btn'
            data-dismiss='modal'
            onClick={isEdit != true ? createGroup : editDisplay}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default FiltrationPanelAddGroup;
