import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  getCurrentSSOUser,
  getCurrentUser,
  getJwt,
} from "./services/authService";
import ChatLogin from "./components/generalEvent/chatModule/chatLogin/chatLogin";
import "./index.css";
import "./App.css";
import Login from "./components/login/login";
import LoginForm from "./components/login/loginForm";
import RevokeForm from "./components/revokeForm/revokeForm";
import ForgetPassword from "./components/login/forgetForm";
import FAQMain from "./components/FAQ/FaqMain";
import TermsAndConditionMain from "./components/termsAndConditions/termsAndConditionsMain";

import NavBar from "./components/common/navBar/navBar";

import ProjectDetailsForm from "./components/projectDetailsForm/projectDetailsForm";
import { ConsentProvider } from "./contexts/consentContext";
import { GuardianProvider } from "./contexts/guardianContext";
import { MainUserProvider } from "./contexts/mainUserContext";
import { ProjectProvider } from "./contexts/projectContext";
import { SuperAdminProvider } from "./contexts/superadminContext";
import SuperadminHome from "./components/superadmin/superadminHome";
import AddSuperAdmin from "./components/superadmin/SuperAdmin";
import CoachRoom from "./components/superadmin/coachRoom";
import Generalusermanagement from "./components/superadmin/generalusermanagement";
import SessionConfig from "./components/superadmin/sessionConfig";
import SuperAdminInnerHome from "./components/superadmin/superAdminInnerHome";
import Home from "./components/home/home";

// import MainUserDetails from "./generalPages/mainUserDetails";
import AgendaConfig from "./components/superadmin/agendaConfig";
import ProjectsPreview from "./components/superadmin/projectsPreview";
import ProjectDetails from "./components/superadmin/projectDetails";
import SuperAdminInnerProjectHome from "./components/superadmin/superadminProjectsinnerPage";
// import RegionPage from "./generalPages/regionPage/regionPage";

import RegionPage from "./components/registration/regionPage/regionPage";
// import EgyptRegistration from "./components/registration/Egypt/egyptRegistration";
import EgyptRegister from "./components/registration/Egypt/egyptRegister";
import IndiaRegister from "./components/registration/India/indiaRegister";
import FiltrationPanel from "./components/superadmin/filtrationPanel";
import RomaniaRegister from "./components/registration/Romania/romaniaRegister";

import FiltrationPanelAdmin from "./components/generalEvent/panelAdmin/filtrationPanel/filtrationPanelAdmin";
import FiltrationPanelAdminGroups from "./components/generalEvent/panelAdmin/filtrationPanel/filtrationPanelAdminGroups";
import JudgementPanels from "./components/generalEvent/judgementPanels";
import FiltrationPanelJudge from "./components/generalEvent/panelAdmin/filtrationPanel/filtrationPanelJudge";
import FiltrationPanelCalculation from "./components/generalEvent/panelAdmin/filtrationPanel/filtrationPanelCalculation";
import ProjectPanelsPreview from "./components/common/projectPanelspreview/projectPanelsPreview";
import PanelJudge from "./components/generalEvent/panelJudge/panelJudge";
import FiltrationPanelGroup from "./components/superadmin/filtrationPanelGroup";
import Dashboard from "./components/superadmin/dashboard";
import axios from "axios";
import EntrancePage from "./components/generalEvent/entrancePage";
import { StepsProvider } from "./contexts/StepsContext";

import Reception from "./components/home/3DReception";
import Announcments from "./components/generalEvent/announcments";
import CoachesRooms from "./components/generalEvent/coachesRooms/coachesRooms";
import RevokeRequests from "./components/superadmin/revokeRequests";
import Announcements from "./components/superadmin/Announcements/Announcements";
import EntranceHall from "./components/generalEvent/hall";
import Session from "./components/common/session/session";
import BreakroomListing from "./components/generalEvent/breakroomListing";
import RememberCards from "./components/generalEvent/Games/rememberCardsGame/Game";
import { AscentionSudokuProvider } from "./components/generalEvent/Games/sodukuGame/context/SudokuContext";
import { Game1 } from "./components/generalEvent/Games/sodukuGame/Game";
import PhotoboothListing from "./components/generalEvent/photoboothListing";
import Photobooth from "./components/generalEvent/photoGalleries/photobooth";
import Mosaicwall from "./components/generalEvent/photoGalleries/mosaicwall";
import Mosaicwall2 from "./components/generalEvent/photoGalleries/mosaicwall2";
import Agenda from "./components/generalEvent/agenda/agenda";
import Hall2 from "./components/generalEvent/hall2";
import { setJwt } from "./services/httpService";
import ProtectedRoute from "./components/common/protectedRoute";
import Hall3 from "./components/generalEvent/hall3";
import Hall4 from "./components/generalEvent/hall4";
import VideoScreen1 from "./components/VideoScreens/VideoScreen1";
import VideoScreen2 from "./components/VideoScreens/VideoScreen2";
import VideoScreen3 from "./components/VideoScreens/VideoScreen3";
import VideoScreen4 from "./components/VideoScreens/VideoScreen4";
import VideoScreen5 from "./components/VideoScreens/VideoScreen5";
import VideoScreen6 from "./components/VideoScreens/VideoScreen6";
import FailedParticipants from "./components/superadmin/FailedParticipants";
import ChangeForm from "./components/login/changeForm";
import NotApproved from "./components/generalEvent/NotApproved";
import Cookies from "./components/Cookies/Cookies";
import EntranceNonRegistered from "./components/generalEvent/entranceNonRegistered";
import RegisterationClosed from "./components/generalEvent/RegisterationClosed";
import Home2 from "./components/home2/Home2";
import { ReservedPathProvider } from "./contexts/reservedPathContext";
import styles from "./App.module.css";
import CookieNotice from "./components/Cookies/CookieNotice";
import CookieAccordion from "./components/Cookies/CookieAccordion";
import CookiesDetails from "./components/Cookies/CookiesDetails";
import MailTemplates from "./components/superadmin/MailTemplates/MailTemplates";
import ViewEmailTemplate from "./components/superadmin/MailTemplates/ViewEmailTemplate";
import MailGroups from "./components/superadmin/mailGroups";
import NotFound from "./components/common/NotFound";
// import RegisterNewProject from "./components/generalEvent/registerNewProject/RegisterNewProject";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function App() {
  const forceUpdate = useForceUpdate();
  const [current, setCurrent] = React.useState({});
  const [acceptCoockies, setAcceptCoockies] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [DetailsTarget, setDetailsTarget] = useState("");
  const myObjects = {
    Targeting_Cookies: [
      {
        name: "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx",
        host: "https://www.googletagmanager.com/gtag/js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description: "Google Analytics",
        Duration: "0 Days",
      },
      {
        name: "_ga_xxxxxxxxxx",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description:
          "This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assigns a randomly generated number to identify unique visitors",
        Duration: "1 Years",
      },
      {
        name: "_fbp",
        host: "https://connect.facebook.net/en_US/fbevents.js",
        category: "Targeting Cookies",
        type: "3rd Party",
        description:
          "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers",
        Duration: "2 Months",
      },
    ],
    Performance_Cookies: [
      {
        name: "_ga",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Performance Cookies",
        type: "3rd Party",
        description:
          "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.  By default it is set to expire after 2 years, although this is customisable by website owners.",
        Duration: "2 Years",
      },
      {
        name: "_gid",
        host: "https://www.google-analytics.com/analytics.js",
        category: "Performance Cookies",
        type: "3rd Party",
        description:
          "This cookie name is associated with Google Universal Analytics. This appears to be a new cookie and as of Spring 2017 no information is available from Google.  It appears to store and update a unique value for each page visited.",
        Duration: "1 Days",
      },
    ],
    // other properties...
  };

  useEffect(() => {
    getCurrentSSOUser().then((r) => {
      if (current == {}) setCurrent(r);
    });
    setAcceptCoockies(localStorage.getItem("acceptedCookies"));
  }, [localStorage.getItem("acceptedCookies")]);
  const [pdflink, setpdflink] = useState(undefined);
  useEffect(() => {
    if (!window.location.href.includes("/superadmin")) {
      axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
        link: window.location.href,
      });
    }
    axios.post(`${process.env.REACT_APP_SERVER_URL}invetations/addlinkcount`, {
      link: window.location.href,
    });

    setJwt(getJwt());
  }, []);
  useEffect(() => {}, [acceptCoockies]);
  const cookiesRejectModal = () => {
    localStorage.setItem("acceptedCookies", "False");
    setAcceptCoockies(false);
    window.shouldLoadFacebookPixel = false;
    forceUpdate();

    document.getElementById("managecookiesbtn").click();
  };
  const cookiesReject = () => {
    localStorage.setItem("acceptedCookies", "False");
    setAcceptCoockies(false);
    window.shouldLoadFacebookPixel = false;
    forceUpdate();
  };

  const CookieAcceptModal = (confirm) => {
    if (confirm) {
      localStorage.setItem("acceptedCookies", "True");
      setAcceptCoockies(true);
      window.shouldLoadFacebookPixel = true;

      const event = new Event("loadAnalyticsUpdate");
      window.dispatchEvent(event);
    } else {
      localStorage.setItem("acceptedCookies", "True");
      setAcceptCoockies(true);
      window.acceptTargetting = targetting;
      window.acceptPerformance = performance;

      const event = new Event("loadCookiesConfirm");
      window.dispatchEvent(event);
    }
    forceUpdate();
    document.getElementById("managecookiesbtn").click();
  };
  const CookieAccept = () => {
    localStorage.setItem("acceptedCookies", "True");
    setAcceptCoockies(true);
    window.shouldLoadFacebookPixel = true;

    const event = new Event("loadAnalyticsUpdate");
    window.dispatchEvent(event);
    forceUpdate();
  };
  const cookieManage = () => {
    if (localStorage.getItem("acceptedCookies")) {
      localStorage.removeItem("acceptedCookies");
    }

    setAcceptCoockies(true);

    document.getElementById("managecookiesbtn").click();
  };
  const [performance, setPerformance] = useState(false);
  const [targetting, setTargeting] = useState(false);
  const toggleperformanceCookies = (checked) => {
    console.log(checked);
    setPerformance(checked);
    forceUpdate();
  };
  const toggletargetCookies = (checked) => {
    console.log(checked);
    setTargeting(checked);
    forceUpdate();
  };
  const onViewDetails = (DetailsName) => {
    console.log(DetailsName);
    setViewDetails(true);
    setDetailsTarget(DetailsName.replace(" ", "_"));
  };
  const cookies = [
    {
      title: "Strictly Necessary Cookies",
      usetoggle: false,
      descrition:
        "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information. ​",
    },
    {
      title: "Targeting Cookies",
      usetoggle: true,
      onTog: toggletargetCookies,
      descrition:
        "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.​",
      viewDetails: onViewDetails,
    },
    {
      title: "Performance Cookies",
      usetoggle: true,
      onTog: toggleperformanceCookies,
      descrition:
        "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.​",
      viewDetails: onViewDetails,
    },
  ];

  return (
    <>
      <button
        type='button'
        class='btn btn-primary'
        data-toggle='modal'
        data-target='#managecookies'
        id='managecookiesbtn'
        style={{ display: "none" }}
      >
        wivawelcome
      </button>
      <div
        className='modal fade '
        id='managecookies'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-backdrop='static'
        data-keyboard='false'
      >
        <div className={" modal-dialog modal-lg"} role='document'>
          <div className={styles["modal-bg"] + " modal-content "}>
            <div className='modal-header' style={{ color: "white" }}>
              Manage Cookies
            </div>
            <div
              className='modal-body row'
              style={{
                padding: "4% 4.5%",
              }}
            >
              {viewDetails ? (
                <div className={styles["modal-body"]}>
                  <span className={styles["modal-section-header"]}>
                    <img
                      src='/assets/back-arrow.svg'
                      className='pr-2'
                      onClick={() => {
                        setViewDetails(false);
                        setDetailsTarget(undefined);
                      }}
                    ></img>{" "}
                    {DetailsTarget}
                  </span>

                  <div className={styles["accordion-div"]}>
                    {myObjects[DetailsTarget].map((e, i) => {
                      console.log("r", e);
                      return (
                        <CookiesDetails
                          title={e.name}
                          description={e.description}
                          Duration={e.Duration}
                          host={e.host}
                          category={e.category}
                          type={e.type}
                          index={i}
                        ></CookiesDetails>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className={styles["modal-body"]}>
                  <span className={styles["modal-section-header"]}>
                    Privacy Preference Center
                  </span>
                  <span className={styles["modal-section-body"]}>
                    When you visit any website, it may store or retrieve
                    information on your browser, mostly in the form of cookies.
                    This information might be about you, your preferences or
                    your device and is mostly used to make the site work as you
                    expect it to. The information does not usually directly
                    identify you, but it can give you a more personalized web
                    experience. Because we respect your right to privacy, you
                    can choose not to allow some types of cookies. Click on the
                    different category headings to find out more and change our
                    default settings. However, blocking some types of cookies
                    may impact your experience of the site and the services we
                    are able to offer.
                  </span>
                  <a
                    href='/cookienotice'
                    style={{
                      color: "#e60000",
                      fontWeight: "bold",
                      display: "block",
                      margin: "1rem 0",
                    }}
                  >
                    More Information
                  </a>{" "}
                  <span
                    className={styles["cookie-btn"]}
                    onClick={() => CookieAcceptModal(true)}
                  >
                    Accept All
                  </span>
                  <span className={styles["modal-section-header"]}>
                    Manage Consent Preferences
                  </span>
                  <div className={styles["accordion-div"]}>
                    {cookies.map((e, i) => (
                      <CookieAccordion
                        title={e.title}
                        description={e.descrition}
                        usetoggle={e.usetoggle}
                        onTog={e.onTog}
                        index={i}
                        details={e.viewDetails}
                        showDetails={false}
                      ></CookieAccordion>
                    ))}
                  </div>
                </div>
              )}{" "}
            </div>
            <div className='modal-footer float-right'>
              <span
                className={styles["cookie-btn"]}
                onClick={cookiesRejectModal}
              >
                Reject All
              </span>
              <span
                className={styles["cookie2-btn"]}
                onClick={() => CookieAcceptModal(false)}
              >
                Confirm My Choices
              </span>
            </div>
          </div>
        </div>
      </div>
      {acceptCoockies == undefined ? (
        <Cookies
          onclickAcceptOrReject={cookiesReject}
          onclickAccept={CookieAccept}
          onclickManageCookies={cookieManage}
        ></Cookies>
      ) : acceptCoockies ? null : (
        <Cookies
          onclickAcceptOrReject={cookiesReject}
          onclickAccept={CookieAccept}
          onclickManageCookies={cookieManage}
        ></Cookies>
      )}

      {/* */}
      <ConsentProvider>
        <GuardianProvider>
          <MainUserProvider>
            <ProjectProvider>
              <StepsProvider>
                <SuperAdminProvider>
                  <AscentionSudokuProvider>
                    <ReservedPathProvider>
                      <Routes>
                        <Route
                          exact
                          path='/'
                          element={<ProtectedRoute></ProtectedRoute>}
                        >
                          <Route
                            path='/entrance'
                            user={current}
                            element={<EntrancePage />}
                          ></Route>
                          <Route path='/agenda' element={<Agenda />}></Route>
                          <Route path='/session' element={<Session />}></Route>
                          <Route
                            path='/agendaload'
                            element={<Agenda />}
                          ></Route>
                          <Route
                            path='/mosaicwall2'
                            element={<Mosaicwall2 />}
                          ></Route>
                          <Route
                            path='/mosaicwall'
                            element={<Mosaicwall />}
                          ></Route>
                          <Route
                            path='/photobooth'
                            element={<Photobooth />}
                          ></Route>
                          <Route
                            path='/photoboothlisting'
                            element={<PhotoboothListing />}
                          ></Route>
                          <Route
                            path='/chatlogin'
                            element={<ChatLogin />}
                          ></Route>
                          <Route path='/game/1' element={<Game1 />}></Route>
                          <Route
                            path='/game/2'
                            element={<RememberCards />}
                          ></Route>
                          <Route
                            path='/breakroomlisting'
                            element={<BreakroomListing />}
                          ></Route>
                          <Route
                            path='/video6'
                            element={<VideoScreen6 />}
                          ></Route>
                          <Route
                            path='/video4'
                            element={<VideoScreen4 />}
                          ></Route>
                          <Route
                            path='/video5'
                            element={<VideoScreen5 />}
                          ></Route>
                          <Route
                            path='/video3'
                            element={<VideoScreen3 />}
                          ></Route>
                          <Route
                            path='/video1'
                            element={<VideoScreen1 />}
                          ></Route>
                          <Route
                            path='/video2'
                            element={<VideoScreen2 />}
                          ></Route>
                          <Route path='/hall4' element={<Hall4 />}></Route>

                          <Route path='/hall2' element={<Hall2 />}></Route>
                          <Route
                            path='/hall'
                            element={<EntranceHall />}
                          ></Route>

                          <Route
                            path='/announcments'
                            element={<Announcments />}
                          ></Route>
                          <Route
                            path='/coaches'
                            element={<CoachesRooms />}
                          ></Route>
                          {/* <Route
                        path='/registerProject'
                        element={RegisterNewProject}
                      ></Route> */}

                          <Route
                            path='/nonregistered'
                            user={current}
                            element={<EntranceNonRegistered />}
                          ></Route>
                          <Route
                            path='/superadmin/failedParticiapnats'
                            element={<FailedParticipants />}
                          />
                          <Route
                            path='/superadmin/revokerequests'
                            element={<RevokeRequests />}
                          ></Route>
                          <Route
                            path='/superadmin/home'
                            element={<SuperadminHome />}
                          ></Route>
                          <Route
                            path='/superadmin/managesuperadmin'
                            element={<AddSuperAdmin />}
                          ></Route>
                          <Route
                            path='/superadmin/regionalhome'
                            element={<SuperAdminInnerHome />}
                          ></Route>
                          <Route
                            path='/superadmin/dashboard'
                            element={<Dashboard />}
                          ></Route>
                          <Route
                            path='/superadmin/coachroom'
                            element={<CoachRoom />}
                          ></Route>
                          <Route
                            path='/superadmin/users'
                            element={<Generalusermanagement />}
                          ></Route>
                          <Route
                            path='/superadmin/sessions'
                            element={<SessionConfig />}
                          ></Route>
                          <Route
                            path='/superadmin/viewmail'
                            element={<ViewEmailTemplate />}
                          ></Route>
                          <Route
                            path='/superadmin/templates'
                            element={<MailTemplates />}
                          ></Route>
                          <Route
                            path='/superadmin/agenda'
                            element={<AgendaConfig />}
                          ></Route>
                          <Route
                            path='/superadmin/project'
                            element={<ProjectsPreview />}
                          ></Route>
                          <Route
                            path='/superadmin/innerproject'
                            element={<SuperAdminInnerProjectHome />}
                          ></Route>
                          <Route
                            path='/superadmin/projectDetails'
                            element={<ProjectDetails />}
                          ></Route>
                          <Route
                            path='/superadmin/filtrationPanel'
                            element={<FiltrationPanel />}
                          ></Route>
                          <Route
                            path='/superadmin/filtrationpanelgroup'
                            element={<FiltrationPanelGroup />}
                          ></Route>
                          <Route
                            path='/superadmin/announcements'
                            element={<Announcements />}
                          ></Route>
                          {/* <Route path='/userdetails' element={MainUserDetails}></Route> */}

                          <Route
                            path='/filtrationpaneljudge'
                            element={<FiltrationPanelJudge />}
                          ></Route>
                          <Route
                            path='/filtrationpaneladmin'
                            element={<FiltrationPanelAdmin />}
                          ></Route>
                          <Route
                            path='/superadmin/mailgroups'
                            element={<MailGroups />}
                          ></Route>

                          <Route
                            path='/judgesCalculated'
                            element={<FiltrationPanelCalculation />}
                          ></Route>
                          {/*  */}
                          <Route
                            path='/judges'
                            element={<JudgementPanels />}
                          ></Route>
                        </Route>
                        <Route
                          path='/cookienotice'
                          element={<CookieNotice />}
                        ></Route>
                        <Route
                          path='/egyptregistration'
                          element={<EgyptRegister />}
                        ></Route>
                        <Route
                          path='/indiaregistration'
                          element={<IndiaRegister />}
                        ></Route>
                        <Route
                          path='/romaniaregistration'
                          element={<RomaniaRegister />}
                        ></Route>
                        <Route
                          path='/loginform'
                          exact
                          element={<LoginForm />}
                        ></Route>
                        <Route path='/region' element={<RegionPage />}></Route>
                        <Route path='/login' exact element={<Login />}></Route>
                        <Route
                          path='/forgetpassword'
                          element={<ForgetPassword />}
                        ></Route>
                        <Route
                          path='/changepassword'
                          element={<ChangeForm />}
                        ></Route>
                        <Route
                          path='/reception'
                          element={<Reception />}
                        ></Route>
                        <Route path='/FAQ' element={<FAQMain />}></Route>
                        <Route
                          path='/guidelines'
                          element={<TermsAndConditionMain />}
                        ></Route>
                        <Route path='/revoke' element={<RevokeForm />}></Route>
                        <Route path='/' index element={<Home2 />}></Route>
                        <Route path='*' element={<NotFound />} />
                        {/* <Route path='/' element={Home}></Route> */}
                      </Routes>
                    </ReservedPathProvider>
                  </AscentionSudokuProvider>
                </SuperAdminProvider>
              </StepsProvider>
            </ProjectProvider>
          </MainUserProvider>
        </GuardianProvider>
      </ConsentProvider>
    </>
  );
}

export default App;
